<template>

<app-content :loading="is.loading">

	<app-content-head :title="category.name" :tabs="tabs" back="Convention.Registration.Store" />

	<app-content-body :is-grid="(collection.length && isItems) ? 2 : false">

		<app-content-box v-if="isDetails" class="category-description"><div v-html="category.description" /></app-content-box>

		<app-content-box v-if="isMap" class="category-map"><inner-image-zoom :src="category.map" class="category-map-image" /></app-content-box>

		<template v-if="isItems">

			<app-addon v-for="item in collection" :key="item.id" :item="item" v-on:add="onAdd" />

		</template>
		
	</app-content-body>

	<com-assign v-if="is.assigning" :item="assign.item" :variant="assign.variant" v-on:close="is.assigning = false" />

</app-content>

</template>

<script>

import Vue from 'vue'
import comAssign from './conventionregistrationstorecategory/Assign'
import InnerImageZoom from 'vue-inner-image-zoom'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'

export default {

	components: {
		comAssign,
		InnerImageZoom
	},

	data: function() {

		return {
			is: {
				loading: true,
				assigning: false
			},
			assign: {
				item: false,
				variant: false
			},
			category: false,
			collection: []
		}

	},

	created: function() {

		this.load()

	},

	computed: {

		isMap: function() {

			return this.$route.name === 'Convention.Registration.Store.Category.Map'

		},

		isDetails: function() {

			return this.$route.name === 'Convention.Registration.Store.Category.Details'

		},

		isItems: function() {

			return this.$route.name === 'Convention.Registration.Store.Category'

		},

		tabs: function() {

			if (this.category.description || this.category.map) {

				var tabs = []

				tabs.push({ name: 'Convention.Registration.Store.Category', text: 'Items' })
				if (this.category.description) tabs.push({ name: 'Convention.Registration.Store.Category.Details', text: 'Details' })
				if (this.category.map) tabs.push({ name: 'Convention.Registration.Store.Category.Map', text: 'Map' })

				return tabs

			} else {

				return false

			}

		}

	},

	methods: {

		hasAdded: function(id, variant) {

			var added = this.$_.filter(this.$store.getters['basket/items'], function(addon) {

				return addon.item.id === id && (variant === false || addon.variant.id === variant)

			})

			return (added.length) ? added : false

		},

		onAdd: function(e) {

			var item = this.$_.findWhere(this.collection, {
				id: e.item
			})

			var variant = (e.variant) ? this.$_.findWhere(item.variants, {
				id: e.variant
			}) : false

			if (this.hasAdded(e.item, e.variant)/* || this.$store.getters['basket/badges'].length*/) {

				if (item.type === this.$constants.registration.item.type.badge) {

					this.$store.commit('basket/remove', {
						item: item,
						variant: variant
					})

				} else {

					this.assign.item = item
					this.assign.variant = variant

					this.is.assigning = true

				}

			} else {

				this.$store.commit('basket/add', {
					badge: this.$store.getters['session/badge'].id,
					item: item,
					variant: variant,
					quantity: 1
				})

			}

		},

		load: function() {

			this.$api.get(['convention/registration/store', this.$route.params.id]).then(function(json) {

				Vue.set(this, 'category', json.category)
				this.collection = json.collection

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.is-desktop .category-description {
	grid-column: 1 / span 2;
	margin-bottom: 0px;
}

.category-description {
	text-align: center;
}

.category-description >>> h1 {
	font-size: 32px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 20px;
	color: #343434;
	text-align: center;
}

.category-description >>> h2 {
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
	margin-bottom: 20px;
	color: #343434;
	text-align: center;
}

.category-description >>> p {
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
	color: #343434;
}

.category-description >>> a {
	color: #387DD8;
	font-weight: 700;
}

.category-description >>> a:hover {
	text-decoration: underline;
}

.category-description >>> *:last-child {
	margin-bottom: 0px;
}

.category-description >>> em,
.category-description >>> i {
	font-style: italic;
}

.category-map {
	height: 100%;
    margin-bottom: 0px;
}

.category-map >>> .box-content {
	min-height: 100%;
}

.category-map-image {
	width: 100%;
	height: 100%;
}

</style>